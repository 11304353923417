.fa {
  padding: 20px;
  font-size: 20px;
  width: 40px;
  height: 40px;
  text-align: center;
  text-decoration: none;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
}

.fa:hover {
  opacity: 0.7;
}

.fa-facebook {
  background: #3b5998;
  color: white;
}

.fa-twitter {
  background: #55acee;
  color: white;
}

.fa-linkedin {
  background: #007bb5;
  color: white;
}
