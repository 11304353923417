:root {
  /*primary*/
  --themeDarker: #9bd0ff;
  --themeDark: #77c0ff;
  --themeDarkAlt: #5eb4ff;

  --themePrimary: #4dacff;
  --themeSecondary: #4397e0;
  --themeTertiary: #2e6799;

  --themeLight: #17344d;
  --themeLighter: #0c1b29;
  --themeLighterAlt: #03070a;
  /*foreground*/
  --black: #000;
  --neutralDark: #77c0ff;
  --neutralPrimary: #ffffff;
  --neutralPrimaryAlt: #dadada;
  --neutralSecondary: #d0d0d0;
  --neutralTertiary: #c8c8c8;
  --white: #fff;
  /*background*/
  --neutralTertiaryAlt: #0d141c;
  --neutralQuaternaryAlt: #0e161f;
  --neutralLight: #0f1721;
  --neutralLighter: #101822;
  --neutralLighterAlt: #101923;
  /*semantic*/
  --neutralQuaternary: #0d151d;
  --surfaceElements: #1b2d3e;
  --bodyBackground: #101923;
  --globalAppHeader: #172635;
  --defaultText: #ffffff;
  --secondaryText: #d4d8dd;
  --red: #ff0000;
  --yellow: #ffff00;
  --darkRed: #8b0000;
  --purple: #bd7df6;
  --green: #23be1b;
  --blue: #002db3;
  --orange: #ff8000;
  --off-white: #dddddd;
  --grey: #9e9e9e;
  --border: rgba(255, 255, 255, 0.3);
  --overlay: rgba(27, 45, 62, 0.4);
  --disabled: rgba(255, 255, 255, 0.5);
  --darkOverlay: rgba(16, 25, 35, 0.6);
}

canvas {
  outline: none !important;
}

.width-height-100-percent {
  width: 100%;
  height: 100%;
}

.pointer {
  cursor: pointer;
}

input[type='time']::-webkit-inner-spin-button {
  font-size: 20px;
  position: relative;
  right: -8px;
}

/* @media print {
  @page html, body {
    width: 100%;
    height: 100%;
    background: red;
    color: red;
    margin: 0;
    overflow: hidden;
    -webkit-print-color-adjust: exact !important;
  }

  .ms-DetailsRow {
    width: 100%;
    overflow: scroll;
  }

} */

input[type='number']::-webkit-outer-spin-button,
input[type='number']::-webkit-inner-spin-button {
  -webkit-appearance: none;
  -moz-appearance: textfield;
  margin: 0;
}
