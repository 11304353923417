* {
  box-sizing: border-box;
  -webkit-overflow-scrolling: touch;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

html,
body, #root {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100vh;
  min-height: 100%;
}
body {
  scroll-behavior: smooth;
  margin: 0;
  font-family: 'Roboto', sans-serif, -apple-system, BlinkMacSystemFont,
    'Segoe UI', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
    'Helvetica Neue';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: radial-gradient(
    circle,
    rgb(77, 172, 255) -14%,
    rgb(16, 25, 35) 66%
  );
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
